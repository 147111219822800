import './App.css';
import React, { useRef, useEffect, useState } from "react";

import keith from './media/keith.png';
import gcc from './media/gcc.png';
import soccerpic from './media/soccerpic.png';
import golang from './media/golang.png';
import javascript from './media/javascript.png';
import react from './media/react.png';
import angular from './media/angular.png';
import resume from './media/KeithGrantResume.pdf';
import apt304 from './media/apt304logo.png';
import sena from './media/sena.png';
import onlywire from './media/onlywire.png';
import {elementScrollIntoView} from "seamless-scroll-polyfill";


const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return { height, offsetTop, offsetBottom,};
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

function App() {
  const executeScroll0 = () => {
    elementScrollIntoView(document.querySelector(".body"), {behavior: "smooth", block: "start", inline: "center" });
  }
  
  window.onscroll = function (e) {  
    document.querySelector("#scroll-indicator").style.display = "none";
  } 

  const openNavButtons = () => {
    if (document.getElementById("drop-down").style.display === "none") {
      setTimeout(() => {document.getElementById("drop-down").style.display ="flex";}, 100);
      document.querySelector('html').style.overflow = "hidden";
      document.querySelector('#root').style.overflow = "hidden";
      document.getElementById("btn-line3").style.display = 'none';
      document.getElementById("btn-line2").style.display = 'none';
      document.getElementById("btn-line1").style.display = 'none';
      document.getElementById("close-bttn").style.display = 'flex';
      document.getElementById("drop-down").style.animation= "slideInLeft 0.2s ease-in";
      document.getElementById("grey-blur").style.display = "flex"
    } else {
      document.querySelector('html').style.overflow = "scroll";
      document.querySelector('#root').style.overflow = "scroll";
      document.getElementById("btn-line3").style.display = 'flex';
      document.getElementById("btn-line2").style.display = 'flex';
      document.getElementById("btn-line1").style.display = 'flex';
      document.getElementById("close-bttn").style.display = 'none';
      document.getElementById("drop-down").style.animation= "slideOutLeft 0.2s ease-in";
      document.getElementById("grey-blur").style.display = "none"
      setTimeout(() => {document.getElementById("drop-down").style.display ="none";}, 200);
    }
  }

  //if window is resize to bigger model while hamburger options are open
  var width = window.innerWidth;
  window.addEventListener('resize', function() {
      if(window.innerWidth !== width && window.innerWidth > 800 ){
        document.querySelector('html').style.overflow = "scroll";
        document.querySelector('#root').style.overflow = "scroll";
        document.getElementById("btn-line3").style.display = 'flex';
        document.getElementById("btn-line2").style.display = 'flex';
        document.getElementById("btn-line1").style.display = 'flex';
        document.getElementById("close-bttn").style.display = 'none';
        document.getElementById("grey-blur").style.display = "none"
        setTimeout(() => {document.getElementById("drop-down").style.display ="none";}, 200);
      } 
    }
  );

  const [visibleSection, setVisibleSection] = useState();
  const headerRef = useRef(null);
  const aboutMe = useRef(null);
  const languages = useRef(null);
  const portfolio = useRef(null);
  const skills = useRef(null);

  const sectionRefs = [
    { section: "AboutMe", ref: aboutMe },
    { section: "Languages", ref: languages },
    { section: "Portfolio", ref: portfolio },
    { section: "Skills", ref: skills },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
  <div className="body">

  <div id="scroll-indicator">
    <svg width="24px" height="63px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-708.000000, -700.000000)" stroke="#FFFFFF" strokeWidth="2">
          <g transform="translate(708.000000, 700.000000)">
            <line x1="11.5" y1="9.5" x2="11.5" y2="15.5" id="Line-3" strokeLinecap="round" strokeLinejoin="round"></line>
            <rect id="Rectangle" x="1" y="1" width="22" height="39" rx="11"></rect>
          </g>
        </g>
      </g>
    </svg>
    <svg id="arrow" width="24px" height="63px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-708.000000, -700.000000)" stroke="#FFFFFF" strokeWidth="2">
          <g transform="translate(708.000000, 700.000000)">
            <line x1="1.5" y1="52.5" x2="11.5" y2="61.5" id="Line-4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="11.5" y1="52.5" x2="21.5" y2="61.5" id="Line-4" strokeLinecap="round" strokeLinejoin="round" transform="translate(16.500000, 57.000000) scale(-1, 1) translate(-16.500000, -57.000000) "></line>
          </g>
        </g>
      </g>
    </svg>
  </div>

    <div className="nav-bar" ref={headerRef}>
      <div id="nav-bar-margin">
        <div id="title-name" onClick={executeScroll0}>Keith Grant</div>
        <div className="nav-button-row">
          <div onClick={() => { scrollTo(aboutMe.current);}} className={`nav-button ${visibleSection === "AboutMe" ? "selected" : ""}`} >About Me</div>
          <div onClick={() => { scrollTo(languages.current);}} className={`nav-button ${visibleSection === "Languages" ? "selected" : ""}`}>Languages</div>
          <div onClick={() => { scrollTo(portfolio.current);}} className={`nav-button ${visibleSection === "Portfolio" ? "selected" : ""}`}>Portfolio</div>
          <div onClick={() => { scrollTo(skills.current);}} className={`nav-button ${visibleSection === "Skills" ? "selected" : ""}`}>Skills</div>
          <a id="github-nav" href="https://github.com/kgrant8" target = "_blank">
          <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 0C6.04125 0 0 5.96465 0 13.3288C0 19.2268 3.86437 24.2085 9.23063 25.9745C9.90563 26.0912 10.1587 25.6913 10.1587 25.3414C10.1587 25.0249 10.1419 23.9752 10.1419 22.8589C6.75 23.4754 5.8725 22.0425 5.6025 21.2928C5.45062 20.9096 4.7925 19.7267 4.21875 19.4101C3.74625 19.1602 3.07125 18.5437 4.20188 18.5271C5.265 18.5104 6.02437 19.4934 6.2775 19.8933C7.4925 21.9092 9.43313 21.3428 10.2094 20.9929C10.3275 20.1265 10.6819 19.5434 11.07 19.2102C8.06625 18.8769 4.9275 17.7273 4.9275 12.6291C4.9275 11.1795 5.45062 9.97995 6.31125 9.04694C6.17625 8.71372 5.70375 7.34751 6.44625 5.5148C6.44625 5.5148 7.57688 5.16492 10.1587 6.881C11.2388 6.5811 12.3863 6.43116 13.5338 6.43116C14.6813 6.43116 15.8288 6.5811 16.9088 6.881C19.4906 5.14826 20.6213 5.5148 20.6213 5.5148C21.3638 7.34751 20.8913 8.71372 20.7563 9.04694C21.6169 9.97995 22.14 11.1629 22.14 12.6291C22.14 17.744 18.9844 18.8769 15.9806 19.2102C16.47 19.6267 16.8919 20.4264 16.8919 21.676C16.8919 23.4587 16.875 24.8916 16.875 25.3414C16.875 25.6913 17.1281 26.1078 17.8031 25.9745C23.1356 24.2085 27 19.2102 27 13.3288C27 5.96465 20.9587 0 13.5 0Z" fill="#EDEDF5"/>
          </svg>
          </a>
        </div>

        <button id="drop-down-btn" onClick={openNavButtons}>
          <div id="btn-line1" className="btn-line"></div>
          <div id="btn-line2" className="btn-line"></div>
          <div id="btn-line3" className="btn-line"></div>
          <div id="close-bttn">X</div>
        </button>
        <div id="drop-down" style = {{display: `${'none'}`}}> 
          <div onClick={() => {scrollTo(aboutMe.current); openNavButtons()}} className={`nav-button ${visibleSection === "AboutMe" ? "selected" : ""}`}>About Me</div>
          <div onClick={() => {scrollTo(languages.current); openNavButtons()}} className={`nav-button ${visibleSection === "Languages" ? "selected" : ""}`}>Languages</div>
          <div onClick={() => {scrollTo(portfolio.current); openNavButtons()}} className={`nav-button ${visibleSection === "Portfolio" ? "selected" : ""}`}>Portfolio</div>
          <div onClick={() => {scrollTo(skills.current); openNavButtons()}} className={`nav-button ${visibleSection === "Skills" ? "selected" : ""}`}>Skills</div>
          <a href="https://github.com/kgrant8" target = "_blank">
            <svg id="github-nav" width="35" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M17.5 0C7.83125 0 0 7.79992 0 17.43C0 25.1428 5.00937 31.6572 11.9656 33.9667C12.8406 34.1192 13.1687 33.5963 13.1687 33.1388C13.1687 32.7248 13.1469 31.3522 13.1469 29.8924C8.75 30.6986 7.6125 28.8249 7.2625 27.8444C7.06562 27.3433 6.2125 25.7964 5.46875 25.3824C4.85625 25.0556 3.98125 24.2495 5.44688 24.2277C6.825 24.2059 7.80937 25.4914 8.1375 26.0143C9.7125 28.6506 12.2281 27.9098 13.2344 27.4522C13.3875 26.3193 13.8469 25.5567 14.35 25.121C10.4563 24.6852 6.3875 23.1819 6.3875 16.5149C6.3875 14.6194 7.06562 13.0507 8.18125 11.8306C8.00625 11.3949 7.39375 9.60828 8.35625 7.21166C8.35625 7.21166 9.82187 6.75412 13.1687 8.99823C14.5687 8.60606 16.0563 8.40997 17.5438 8.40997C19.0312 8.40997 20.5188 8.60606 21.9188 8.99823C25.2656 6.73234 26.7313 7.21166 26.7313 7.21166C27.6938 9.60828 27.0812 11.3949 26.9062 11.8306C28.0219 13.0507 28.7 14.5976 28.7 16.5149C28.7 23.2037 24.6094 24.6852 20.7156 25.121C21.35 25.6657 21.8969 26.7115 21.8969 28.3455C21.8969 30.6768 21.875 32.5505 21.875 33.1388C21.875 33.5963 22.2031 34.141 23.0781 33.9667C29.9906 31.6572 35 25.121 35 17.43C35 7.79992 27.1688 0 17.5 0Z" fill="#EDEDF5"/>
            </svg>   
          </a>
        </div>

      </div>
    </div>
    <div id="grey-blur"></div>

    <div className="box1">
      <div id="box1-margin" className="box-margin">
        <div className="introduction-box1">
         <img id="gcc-badge1" src={gcc} alt="google cloud certified"/> 
          <div id="box1-title1">Orange County Based Software Developer</div>
          <div id="box1-title2">Keith Grant</div>
          <a href="mailto:KeithGrant08@gmail.com">
            <div className="contact-me1">Contact Me</div>
          </a>
        </div>
        <div className="introduction-box2">
          <img id="keith-profile" src={keith} alt="keith"/> 
          <div id="profile-backdrop"></div>
        </div>
      </div>
    </div>
    <div className="box2">
      <div id="box2-margin" className="box-margin">
        <div ref={aboutMe} className="about-me">
          <div id="about-me-box1">
            <div id="box2a-title" className="box-title">About Me</div>
            <div className="box2-dec">
              <div id="box2-title2">I specialize in web & highly available apps</div>
              <div id="subtext-box2" className="subtext">When I am not coding you might find me playing soccer or running.</div>
              <div className="about-me-buttons">
                <a href={resume} target = "_blank">
                  <div id="resume1" className="resume">Resume 
                    <svg style={{marginLeft: "10px"}} width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.42419 1.05096V8.20096" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M0.872803 10.8481H8.0228" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.6752 4.86292L4.42322 8.19818" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1.17163 4.8681L4.42361 8.20337" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </a>
                <a href="mailto:KeithGrant08@gmail.com">
                  <div className="contact-me2">Contact Me</div>
                </a>
              </div>
            </div>
          </div>
          <div id="about-me-box2">
            <img id="soccerpic" src={soccerpic} alt="Keith's soccer team"/> 
          </div>
        </div>
        <div id="divider"></div>
        <div ref={languages} id="languages">
          <div id="box2b-title" className="box-title">Languages</div>
          <div id="box2-subtext" className="subtext">I have experience with a number of languages, but these are a few I am most experienced with. </div>    
          <div className="languages">
            <div className="language-align">
              <img style={{paddingTop: '13px', paddingBottom: '13px'}} id="language-symbol" src={golang} alt="golang"/> 
              <div id="language-title">Golang</div>
            </div>
            <div className="language-align">
              <img id="language-symbol" src={javascript} alt="javascript"/> 
              <div id="language-title">Node JS</div>
            </div>
            <div className="language-align">
              <img id="language-symbol" src={react} alt="react"/> 
              <div id="language-title">React</div>
            </div>
            <div className="language-align">
            <img id="language-symbol" src={angular} alt="angular"/> 
              <div id="language-title">Angular</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref={portfolio} className="box3">
      <div id="box3-margin" className="box-margin">
        <div className="box-title">Portfolio</div>
          <div className="portfolio">
            <div id="portfolio-logo">
              <a href="https://www.apartment304.com/" target = "_blank">
                <img className="portfolio-img" src={apt304} alt="apt304"/> 
              </a>
            </div>
            <div id="portfolio-logo">
              <a href="https://www.sena.com/" target = "_blank">
                <img className="portfolio-img" src={sena} alt="sena"/> 
              </a>
            </div>
            <div id="portfolio-logo">
              <a href="https://www.onlywire.com/" target = "_blank">
                <img className="portfolio-img" src={onlywire} alt="onlywire"/> 
              </a>
            </div>
          </div>
          <a href="https://github.com/kgrant8" target = "_blank">
            <div className="github-bttn">Github</div>
          </a>
      </div>

    </div>
    <div ref={skills} className="box4">
      <div id="box4-margin" className="box-margin">
        <div className="box-title">Skills</div>
        <div id="subtext-box4" className="subtext">Cloud and services I am experienced with and my certifications.</div>
        <div className="skills">
          <img id="gcc-badge2" src={gcc} alt="google cloud certified"/> 
          <div id="divider2"></div>
          <div id="divider4"></div>
          <div className="skills-box">
            <div id="box4-text">Amazon Web Services (AWS)</div>
            <div id="box4-text">Google Cloud Platform (GCP)</div>
            <div id="box4-text">Kubernetes</div>
            <div style={{marginBottom: "0px"}} id="box4-text">Postgres</div>
          </div>
        </div>
      </div>
    </div>

    <div className="box5">
      <div id="box5-margin" className="box-margin">
        <div className="footer-box1">
          <div id="box5-title">Keith Grant</div>
          <div className="quick-access">
            <div onClick={() => { scrollTo(aboutMe.current);}} className="footer-bttn">About Me</div>
            <div onClick={() => { scrollTo(languages.current);}} className="footer-bttn">Languages</div>
            <div onClick={() => { scrollTo(portfolio.current);}}className="footer-bttn">Portfolio</div>
            <div style={{marginRight: "0px"}} onClick={() => { scrollTo(skills.current);}} className="footer-bttn">Skills</div>
          </div>
        </div>
        <div className="footer-box2">
          <div id="footer-buttons">
          <a href={resume} target = "_blank">
              <div id="resume2" className="resume">Resume 
                <svg style={{marginLeft: "10px"}} width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.42419 1.05103V8.20103" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M0.872803 10.8481H8.0228" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.67526 4.86304L4.42328 8.1983" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.17163 4.86816L4.42361 8.20343" stroke="#309BF6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </a>
            <a href="mailto:KeithGrant08@gmail.com">
              <div className="contact-me2">Contact Me</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default App;
